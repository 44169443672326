import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import NewPage from './NewPage'; 

function App() {
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [socket, setSocket] = useState(null);
  const [webRtcTransport, setWebRtcTransport] = useState(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  // Открываем WebSocket соединение
  const initializeWebSocket = () => {
    const ws = new WebSocket('wss://api.cmlinecompany.com:4000'); // Замените на ваш реальный адрес WebSocket
    ws.onopen = () => {
      console.log('WebSocket соединение установлено');
      setSocket(ws); // Устанавливаем сокет только когда соединение открыто
    };
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      handleServerMessages(message);
    };
  };

  useEffect(() => {
    if (!socket) {
      initializeWebSocket(); // Инициализация сокета при загрузке компонента
    }
  }, [socket]);

  const handleServerMessages = async (message) => {
    switch (message.action) {
      case 'transportCreated': {
        const { iceCandidates } = message.params;
        const transport = new RTCPeerConnection({ iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] });

        // Добавляем ICE-кандидаты
        iceCandidates.forEach(candidate => transport.addIceCandidate(candidate));

        // Устанавливаем локальные ICE параметры
        const offer = await transport.createOffer();
        await transport.setLocalDescription(offer);

        // Отправляем ICE параметры на сервер
        if (socket) {
          socket.send(JSON.stringify({
            action: 'connectTransport',
            dtlsParameters: transport.currentLocalDescription
          }));
        }

        setWebRtcTransport(transport);
        break;
      }
      case 'producerCreated': {
        console.log('Producer создан с ID:', message.id);
        break;
      }
      default:
        console.log('Неизвестное действие:', message.action);
    }
  };

  const toggleCamera = async () => {
    if (isCameraOn) {
      const stream = videoRef.current.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
      }
      videoRef.current.srcObject = null;
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    }
    setIsCameraOn(!isCameraOn);
  };

  const startStreaming = async () => {
    if (!socket) {
      console.error('WebSocket не инициализирован');
      return;
    }

    // Отправляем запрос на создание транспорта
    socket.send(JSON.stringify({ action: 'createTransport' }));

    // После создания WebRTC транспорта отправляем видео поток
    const stream = videoRef.current.srcObject;
    stream.getTracks().forEach(track => {
      if (webRtcTransport) {
        webRtcTransport.addTrack(track, stream);
      }
    });

    // Отправляем параметры для производства видео
    socket.send(JSON.stringify({
      action: 'produce',
      kind: 'video',
      rtpParameters: {} // Передаем параметры RTP, если они есть
    }));

    setIsStreaming(true);
  };

  return (
    <div className="app">
      <div className="camera-block">
        <video ref={videoRef} autoPlay playsInline></video>
        <div className="controls">
          <button onClick={toggleCamera}>
            {isCameraOn ? 'Выключить камеру' : 'Включить камеру'}
          </button>
          <button onClick={startStreaming} disabled={!isCameraOn || isStreaming}>
            Начать стрим!!!
          </button>
        </div>
      </div>
      <div className="stream-block">
        {isStreaming ? (
          <video ref={streamRef} autoPlay playsInline className="stream-video"></video>
        ) : (
          <p>Стрим пока не запущен</p>
        )}
      </div>
    </div>
  );
}

export default App;
